import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AudioControlIcon, AudioMuteIcon } from '@rsos/assets/icons';
import { Box, Slider, Text } from '@rsos/components/capstone/base';
import { cspAudioUserSettingsSelector } from '@rsos/reduxDataStore/src/features/selectors/cspUserSettingsSelectors';
import { updateUserAttributes } from '@rsos/sinatra';
import useIsIRP from '@rsos/utils/useIsIRP';
import {
  trackClickMuteAudioControl,
  trackChangeAudioVolume,
} from '../audioControltrackings';
import { SliderWrapper, VolumeSliderTooltip } from './VolumeSlider.styles';

const VolumeSlider = ({ min = 0, max = 1, step = '0.1', type }) => {
  const { irpVersion } = useIsIRP();
  const dispatch = useDispatch();
  const { application: applicationName } = useSelector(
    state => state.sinatra.user.currentRole,
  );
  const userAudioSettings = useSelector(state =>
    cspAudioUserSettingsSelector(state.sinatra),
  );
  const volumeVal = userAudioSettings?.[type]?.volume;
  const [sliderValue, setSliderValue] = useState(volumeVal) || 0.7;
  const [isVisible, setIsVisible] = useState(false);
  const onMouseEnter = useCallback(() => {
    setIsVisible(true);
  }, [isVisible]);
  const onMouseLeave = useCallback(() => {
    setIsVisible(false);
  }, [isVisible]);

  const handleMute = () => {
    const updatedSettings = {
      audio_settings: {
        ...userAudioSettings,
        [type]: { volume: 0 },
      },
    };
    setSliderValue(0);
    dispatch(updateUserAttributes(applicationName, updatedSettings));
    trackClickMuteAudioControl(irpVersion, type);
  };
  const handleVolumeChange = volVal => {
    setSliderValue(volVal);
  };
  const handleMouseUp = () => {
    const data = {
      audio_settings: {
        ...userAudioSettings,
        [type]: { volume: parseFloat(sliderValue) },
      },
    };
    dispatch(updateUserAttributes(applicationName, data));
    trackChangeAudioVolume(irpVersion, type);
  };

  return (
    <SliderWrapper>
      <Text size="normal" color="primaryText">
        Alerts
      </Text>
      <Box
        position="relative"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        cursor="pointer"
      >
        {isVisible && <VolumeSliderTooltip />}
        <AudioMuteIcon color="secondaryLink" onClick={handleMute} />
      </Box>
      <Slider
        onChange={e => handleVolumeChange(e.target.value)}
        onMouseUp={handleMouseUp}
        value={sliderValue}
        min={min}
        max={max}
        step={step}
        style={{
          width: '100%',
          '--min': min,
          '--max': max,
          '--val': sliderValue,
        }}
      />
      <AudioControlIcon color="secondaryLink" />
    </SliderWrapper>
  );
};

export default VolumeSlider;
