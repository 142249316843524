import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import styled from 'styled-components';
import {
  ALERTS_TRAIN_INCIDENT,
  ALERTS_TEST,
} from '@rsos/capstone/src/constants/capabilities';
import { LayoutContext } from '@rsos/components/capstone/base/Layout';
import { CENTRAL_STATION } from '@rsos/constants/appNames';
import { BP_LAPTOP } from '@rsos/constants/breakpoints';
import { useFlag } from '@rsos/flags';
import determineDataPath from '@rsos/utils/determineDataPath';
import useIsIRP from '@rsos/utils/useIsIRP.js';
import { Box } from '../../../base/index.js';
import StyledSmartLink from '../common/StyledSmartLink';
import ThemeControl from '../common/ThemeControl/ThemeControl.js';
import { RapidSOSLogoCompact, FullLogo } from '../common/logo/RapidSOSLogo';
import CSPNavigationControl from './CSPNavigationControl';
import useSoundNotification from './Notifications/useSoundNotification.js';
import TestModeNavBanner from './TestModeBanner';
import TestModeControl from './TestModeControl';
import VolumeControl from './VolumeControl/VolumeControl.js';

const notFoundPath = '/not-found';

const CSPNavigation = withTheme(({ theme, handleLogout, path }) => {
  const { sinatra } = useSelector(state => state);
  const csp_sounds = useFlag('csp_sounds');

  const { name: themeName = '' } = theme.colors;
  const { layout } = useContext(LayoutContext);
  const { testMode } = sinatra?.ui;
  const hasTrainIncidentsAccess = useSelector(
    state =>
      !!state.psaps.currentPSAP?.activeCapabilities?.[ALERTS_TRAIN_INCIDENT],
  );
  const hasAlertsTest = useSelector(
    state => !!state.psaps.currentPSAP?.activeCapabilities?.[ALERTS_TEST],
  );
  const { irp_access_token } = useIsIRP();

  const isLoggedIn = sinatra?.user?.isLoggedIn;
  const isAnchoredPath = path === notFoundPath;
  const disableNavBarNavigation = path === notFoundPath;
  const disableLogoNavigation =
    path ===
    determineDataPath(CENTRAL_STATION, false, irp_access_token, isLoggedIn);

  useSoundNotification();

  return (
    <CSPNavigationWrapper>
      <Box horizontal align="center" position="relative">
        <Box flex="0 0 auto">
          <StyledSmartLink
            to={determineDataPath(
              CENTRAL_STATION,
              false,
              irp_access_token,
              isLoggedIn,
            )}
            type="standalone"
            data-name="navigation-logo-link"
            useAnchor={isAnchoredPath}
            disableNavigation={disableLogoNavigation || disableNavBarNavigation}
          >
            {layout.window.width <= BP_LAPTOP ? (
              <RapidSOSLogoCompact static />
            ) : (
              <FullLogo
                static
                color={themeName === 'dark' ? 'background' : 'primaryText'}
              />
            )}
          </StyledSmartLink>
        </Box>
      </Box>
      {hasAlertsTest && testMode && hasTrainIncidentsAccess && (
        <TestModeNavBanner />
      )}
      {sinatra.user.isLoggedIn && !disableNavBarNavigation && (
        <Box horizontal justify="flex-end">
          {hasAlertsTest && hasTrainIncidentsAccess && (
            <TestModeControl data-name="navigation-test-mode-control" />
          )}
          {csp_sounds && <VolumeControl />}
          <ThemeControl path={path} data-name="navigation-theme-control" />
          <CSPNavigationControl
            user={sinatra.user}
            handleLogout={handleLogout}
            irpAccessToken={irp_access_token}
            data-name="navigation-navigation-control"
          />
        </Box>
      )}
    </CSPNavigationWrapper>
  );
});

export default CSPNavigation;

const CSPNavigationWrapper = styled(Box).attrs({
  horizontal: true,
  align: 'center',
  justify: 'space-between',
  width: '100%',
})``;
