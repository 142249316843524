import { createSelector } from '@reduxjs/toolkit';
import { CENTRAL_STATION } from '@rsos/constants/adminContents';
import { CSP_USER_SETTINGS_DEFAULT } from '../../../../fixtures';

const resolveUserSettings = user => {
  return (
    user.profile?.attributes?.[CENTRAL_STATION] || CSP_USER_SETTINGS_DEFAULT
  );
};

/**
 * selector to return CSP user settings or the default value
 */

export const cspUserSettingsSelector = createSelector(
  sinatra => sinatra,
  sinatra => {
    return resolveUserSettings(sinatra.user);
  },
);

const resolveAudioUserSettings = user => {
  if (
    user.profile?.attributes?.[CENTRAL_STATION] &&
    user.profile?.attributes?.[CENTRAL_STATION].audio_settings
  ) {
    return user.profile?.attributes?.[CENTRAL_STATION].audio_settings;
  } else {
    return CSP_USER_SETTINGS_DEFAULT.audio_settings;
  }
};

/**
 * selector to return CSP audio user settings or the default value
 */

export const cspAudioUserSettingsSelector = createSelector(
  sinatra => sinatra,
  sinatra => {
    return resolveAudioUserSettings(sinatra.user);
  },
);
